<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <XzTagGroup :data="statusWithAll" :active="outform.subscriptionStatus"
          @handleClick="(v) => handleOutChange('subscriptionStatus', v)">
          <span class="title">协同订阅状态</span>
        </XzTagGroup>
        <div class="table-wrapper" :class="{ 'fullscreen': isFullScreen }">
          <xz-table ref="xzTable" :columns="columnsLists" :data="data" :searchAttrs="searchAttrs"
            :searchVal.sync="outform.enterpriseName" :toolsAttrs="toolsAttrs" :drawerVisible.sync="drawerVisible"
            :scroll="{ x: '100%', y: scrollHeight }" :drawerWidth="460" @search="() => handleOutChange()" rowKey="id"
            @refresh="handleReset" @fullScreen="handleFullScreen" class="wrap">
            <template #drawer>
              <div style="flex: 1; overflow: auto" class="advanced-search">
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
                  <a-form-model-item label="协同订阅状态">
                    <a-select v-model="form.subscriptionStatus" placeholder="请选择协同订阅状态" allow-clear>
                      <a-select-option value="">全部</a-select-option>
                      <a-select-option :value="0">待订阅</a-select-option>
                      <a-select-option :value="1">已订阅</a-select-option>
                      <a-select-option :value="2">已取消</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="供需通任务编号">
                    <a-input v-model="form.taskNo" placeholder="请输入供需通任务编号" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="系统协同分类">
                    <a-select v-model="form.systemCooperationCategory" placeholder="系统协同分类" allow-clear>
                      <a-select-option value="">全部</a-select-option>
                      <a-select-option :value="1">资管易&小紫平台</a-select-option>
                      <a-select-option :value="2">小紫平台</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="供需通类型" allow-clear>
                    <a-select v-model="form.cooperationType" placeholder="请选择供需通类型" allow-clear>
                      <a-select-option value="">全部</a-select-option>
                      <a-select-option :value="1">采购协同</a-select-option>
                      <a-select-option :value="2">销售协同</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="协同企业名称">
                    <a-input v-model="form.enterpriseName" placeholder="请输入协同企业名称" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="小紫平台企业号">
                    <a-input v-model="form.tenantCode" placeholder="请输入小紫平台企业号" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="发起人">
                    <a-input v-model="form.createUserName" placeholder="请输入发起人" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="联系电话">
                    <a-input v-model="form.createUserPhone" placeholder="请输入联系电话" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="发起开始时间">
                    <a-date-picker v-model="form.createTimeStart" placeholder="请选择发起开始时间" valueFormat="x"
                      style="width: 100%;" allow-clear show-time>
                    </a-date-picker>
                  </a-form-model-item>
                  <a-form-model-item label="发起结束时间">
                    <a-date-picker v-model="form.createTimeEnd" placeholder="请选择发起结束时间" valueFormat="x"
                      style="width: 100%;" allow-clear show-time>
                    </a-date-picker>
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div style="text-align: right; height: 68px; line-height: 68px">
                <a-button @click="handleEmpty">
                  重置
                </a-button>
                <a-button type="primary" style="margin-left: 10px;" @click="handleAdvancedQuery">
                  确定
                </a-button>
              </div>
            </template>
            <template #custom>
              <el-popover placement="left" width="300" :tabindex="10000" popperClass="JZPopper" v-model="showAttribute"
                trigger="manual">
                <div class="top"><span>字段设置</span><time @click="showAttribute = false">&times;</time>
                </div>
                <draggable v-model="lockLists" draggable=".list-item">
                  <template v-if="lockLists && lockLists.length">
                    <li v-for="(item, index) in lockLists" :key="item.fieldName" class="list-item">
                      <el-checkbox v-model="item.isShow" :disabled="item.isDisabled"
                        style="margin-top:6px;"></el-checkbox>
                      <div class="labelContainer">{{ item.fieldName }}</div>
                      <i :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                        style="font-size: 12px;margin-top:10px;margin-left:12px;"
                        @click="handleClickLock(item, index)"></i>
                      <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                    </li>
                  </template>
                  <template v-else>
                    <li class="noFixedItem">
                      锁定下列项目到此固定
                    </li>
                  </template>
                </draggable>
                <h3 style="border-top: 1px solid #DFE2E8;font-size:14px;height:40px;line-height:40px;margin-bottom:0;">
                  不固定</h3>
                <draggable v-model="attrLists" draggable=".list-item">
                  <li v-for="(item, index) in attrLists" :key="item.fieldName" class="list-item">
                    <el-checkbox v-model="item.isShow" :disabled="item.isDisabled"
                      style="margin-top:6px;"></el-checkbox>
                    <div class="labelContainer">{{ item.fieldName }}</div>
                    <i :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                      style="font-size: 12px;margin-top:10px;margin-left:12px;"
                      @click="handleClickLock(item, index)"></i>
                    <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                  </li>
                </draggable>
                <div class="text-right">
                  <el-button type="default" size="mini" @click="handleResetCancel">重置</el-button>
                  <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
                </div>
                <el-button slot="reference" @click='handleSort'
                  style='width:32px;height:32px;margin-left:8px;padding: 8px;'><img
                    :src="require('@/assets/ic_setting.svg')"
                    style='width:16px;height:16px;font-size:16px;' /></el-button>
              </el-popover>
            </template>
            <span slot="action" slot-scope="text, record">
              <a-popconfirm v-if="record.subscriptionStatus === 0" style="margin-right: 6px;" placement="leftTop"
                ok-text="是" cancel-text="否" @confirm="handleSubscribe(record)">
                <template slot="title">
                  <div style="width: 10em;">
                    订阅后对客户指定询单进行报价
                  </div>
                </template>
                <a>订阅</a>
              </a-popconfirm>
              <a-popconfirm v-if="record.subscriptionStatus === 0" placement="leftTop" ok-text="是" cancel-text="否"
                @confirm="handleClose(record)">
                <template slot="title">
                  <div style="width: 10em;">
                    取消任务后，需要供应商重新发起订阅任务才可订阅
                  </div>
                </template>
                <a style="color:#F56C6C;">取消</a>
              </a-popconfirm>
            </span>
          </xz-table>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination :current="page.pageNum" :total="total" :show-total="total => `共 ${total} 条`" show-size-changer
        show-quick-jumper :pageSize.sync="page.pageSize" @change="handleNumChange" @showSizeChange="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import { cooperationSubscriptionPageList, cooperationSubscriptionSubscribe, cooperationSubscriptionCancel } from '@/api/cooperation';
import { getFiledRuleLists, addFiledRule } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import XzTagGroup from '../../../components/xiaozi-ui/xz-tag-group';
import * as Moment from 'dayjs';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

const COMMON_COLUMNS = [
  {
    title: '状态',
    dataIndex: 'subscriptionStatusDesc',
    key: 'subscriptionStatusDesc',
    width: 80,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '供需通任务编号',
    dataIndex: 'taskNo',
    key: 'taskNo',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '系统协同分类',
    dataIndex: 'systemCooperationCategoryDesc',
    key: 'systemCooperationCategoryDesc',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '供需通类型',
    dataIndex: 'cooperationTypeDesc',
    key: 'cooperationTypeDesc',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '协同企业名称',
    dataIndex: 'enterpriseName',
    key: 'enterpriseName',
    width: 180,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '小紫平台企业号',
    dataIndex: 'tenantCode',
    key: 'tenantCode',
    width: 160,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '发起人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '联系电话',
    dataIndex: 'createUserPhone',
    key: 'createUserPhone',
    width: 120,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '发起时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 170,
    ellipsis: true,
    customRender: (text) => {
      return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
  },
];

export default {
  name: 'CooperationSubscription',
  components: {
    xzTable,
    XzTagGroup,
    draggable,
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolsAttrs () {
      return {
        refresh: true,
        zoom: true,
        fullScreen: this.isFullScreen,
        advancedSearch: true,
        custom: true,
      };
    },
  },
  watch: {
    drawerVisible (v) {
      if (v) {
        this.form = { ...this.outform };
      }
    },
  },
  data () {
    return {
      columnsLists: [
        ...COMMON_COLUMNS,
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' },
        }],
      data: [],
      outform: {
        subscriptionStatus: '',
        taskNo: '',
        systemCooperationCategory: '',
        cooperationType: '',
        enterpriseName: '',
        tenantCode: '',
        createUserName: '',
        createUserPhone: '',
        createTimeStart: '',
        createTimeEnd: '',
      },
      form: {
        subscriptionStatus: '',
        taskNo: '',
        systemCooperationCategory: '',
        cooperationType: '',
        enterpriseName: '',
        tenantCode: '',
        createUserName: '',
        createUserPhone: '',
        createTimeStart: '',
        createTimeEnd: '',
      },
      total: 0,
      searchAttrs: {
        show: true,
        placeholder: '请输入企业/组织名称',
        data: 'enterpriseName',
      },
      drawerVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      statusWithAll: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待订阅',
          value: 0,
        },
        {
          label: '已订阅',
          value: 1,
        },
        {
          label: '已取消',
          value: 2,
        },
      ],
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      scrollHeight: 600,
      isFullScreen: false,
      showAttribute: false,
      attrLists: [],
      lockLists: [],
      attrPropLists: [],
      lockPropLists: [],
    };
  },
  methods: {
    handleEmpty () {
      this.form = this.$options.data().form;
    },
    handleReset () {
      this.outform = {
        ...this.$options.data().form,
      };

      this.page.pageNum = 1;
      this.page.pageSize = 10;

      this.handleSearch();
    },
    handleAdvancedQuery () {
      this.page.pageNum = 1;

      this.form.subscriptionStatus = this.form.subscriptionStatus === undefined ? '' : this.form.subscriptionStatus;

      this.handleSearch(this.form);

      this.$nextTick(() => {
        this.outform = { ...this.form };
      });
    },
    handleSizeChange (_, size) {
      this.page.pageSize = size;

      this.handleSearch();
    },
    handleNumChange (num) {
      this.page.pageNum = num;

      this.handleSearch();
    },
    async handleSearch (form = this.outform) {
      this.data = [];
      const { body } = await cooperationSubscriptionPageList({
        ...form,
        ...this.page,
        orderBy: [{
          orderByProperty: 'createTime',
          orderByType: 'DESC',
        }],
      });

      this.data = body?.list || [];

      this.total = body?.total;

      if (this.drawerVisible) {
        this.drawerVisible = false;
      }
    },
    async handleClose (record) {
      await cooperationSubscriptionCancel({
        id: record.id,
      });

      this.handleSearch();
    },
    async handleSubscribe (record) {
      await cooperationSubscriptionSubscribe({
        id: record.id,
      });

      this.handleSearch();
    },
    handleOutChange (key, o) {
      if (key) {
        this.outform[key] = o.value;
      }

      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleFullScreen (flag) {
      this.isFullScreen = flag;
    },
    handleSort () {
      this.attrLists = this.attrPropLists;
      this.lockLists = this.lockPropLists;
      this.showAttribute = true;
    },
    handleResetCancel () {
      let columnLists = JSON.parse(JSON.stringify(COMMON_COLUMNS));
      let attrLists = columnLists.map((i, index) => ({
        fieldKey: i.key,
        fieldName: i.title,
        businessType: 'cooperationSubscription',
        isRegular: false,
        isShow: true,
        sort: index + 1,
      }));
      this.attrLists = attrLists;
      this.lockLists = [];
    },
    handleSave () {
      let lists = [
        ...this.lockLists,
        ...this.attrLists,
      ].map((i, index) => ({
        ...i,
        sort: index + 1,
      }));
      addFiledRule(lists).then(() => {
        // this.handleSearch();这里需要根据结果 调整展示
        this.getRuleLists(lists);
        this.showAttribute = false;
      });
    },
    // 这里需要处理table
    getColumnsLists (lists) {
      let showIdLists = lists.filter(i => i.isShow).map(i => i.fieldKey);
      let fixedIdLists = lists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);

      let currentLists = COMMON_COLUMNS.filter(i => showIdLists.includes(i.dataIndex)).map(i => ({
        ...i,
        fixed: fixedIdLists.includes(i.dataIndex) ? 'left' : '',
      }));
      let tempLists = [];
      for (let i = 0; i < showIdLists.length; i++) {
        const info = currentLists.find(j => j.key === showIdLists[i]);
        tempLists.push(info);
      }

      const columnLists = [...tempLists, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: 150,
        scopedSlots: { customRender: 'action' },
      }];

      this.$nextTick(() => {
        this.purchaseColumns = columnLists;
      });
    },
    handleClickLock (item, index) {
      if (item.isRegular) {
        item.isRegular = false;
        this.lockLists.splice(index, 1);
        this.attrLists.push(item);
      } else {
        item.isRegular = true;
        this.attrLists.splice(index, 1);
        this.lockLists.push(item);
      }
    },
    getRuleLists () {
      getFiledRuleLists({
        businessType: 'cooperationSubscription',
      }).then(({ body }) => {
        let currentLists = body || [];
        currentLists = JSON.parse(JSON.stringify(currentLists));

        if (currentLists && currentLists.length) {
          this.attrLists = currentLists.filter(i => !i.isRegular);
          this.lockLists = currentLists.filter(i => i.isRegular);
          this.getColumnsLists(currentLists);
        } else {
          let columnLists = [...COMMON_COLUMNS, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            scopedSlots: { customRender: 'action' },
          }];

          this.columns = columnLists;

          // 这里需要根据 columnLists 获取需要处理的数据的值
          let lockLists = columnLists.filter(i => i.fixed && i.title !== '操作').map((i, index) => ({
            fieldKey: i.key,
            fieldName: i.title,
            businessType: 'cooperationSubscription',
            isRegular: false,
            isShow: true,
            sort: index + 1,
          }));
          let attrLists = columnLists.filter((i) => !i.fixed).map((i, index) => ({
            fieldKey: i.key,
            fieldName: i.title,
            businessType: 'cooperationSubscription',
            isRegular: false,
            isShow: true,
            sort: lockLists.length + index + 1,
          }));

          this.attrLists = attrLists;
          this.lockLists = lockLists;
        }
        let columnLists = currentLists && currentLists.length ? currentLists : [...COMMON_COLUMNS];
        this.purchaseColumns = columnLists;

        // 这里需要拼接字段属性
        this.attrPropLists = JSON.parse(JSON.stringify(this.attrLists));
        this.lockPropLists = JSON.parse(JSON.stringify(this.lockLists));
      });
    },
  },
  created () {
    this.getRuleLists();
  },
  mounted () {
    this.handleSearch();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 8px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 14px;
        font-weight: 600;
        padding-right: 2em;
      }

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #F2F4F7;

          &.active-radio {
            border-color: #E2F3FE;
            background-color: #E2F3FE;
            color: #237FFA;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .code {
          cursor: pointer;
          color: #237FFA;
        }

        .status-0,
        .status-5 {
          @include tag_round();
          color: #1F2733;
          background-color: #F2F4F7;
        }

        .status-1,
        .status-3,
        .status-6 {
          @include tag_round();
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .status-4 {
          @include tag_round();
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .status-2,
        .status-7 {
          @include tag_round();
          background-color: #FEE9E5;
          color: #FF5959;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-wrapper.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: 100;
  background: #FFFFFF;
}

.text-right {
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}

.top {
  display: flex;
  padding: 6px 0;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #DFE2E8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1F2733 100%;
  letter-spacing: 0px;
  opacity: 1;

  span {
    flex: 1;
    height: 24px;
    line-height: 24px;
  }

  time {
    cursor: pointer;
  }
}

.list-item {
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;

  .el-checkbox__label {
    height: 32px;
    line-height: 32px;

  }

  .labelContainer {
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}

.noFixedItem {
  font-family: PingFang SC;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}

/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #FFFFFF;
}

/deep/.ant-table-body::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px #C9CED6;
  background: #C9CED6;
}

//鼠标移入样式
/deep/ .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #C9CED6;
}

.advanced-search {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

/deep/.ant-drawer-body {
  padding: 16px;
}
</style>
